@import url('https://fonts.googleapis.com/css2?family=ABeeZee&family=Libre+Baskerville&family=Pinyon+Script&display=swap');

:root {
  --gold-one: #B0A15C;
  --gold-two: #b7a34c;
  --bg-one: #B9CBCD;
  --bg-two: rgb(188, 212, 212);
}

*, *::after, *::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body, html {
  min-height: 100%;
  background-color: var(--bg-one);
  scroll-behavior: smooth;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.bg-text {
  color: var(--gold-one);
  word-wrap: normal;
}

.card {
  width: 60%;
  padding: 5em;
  background-image: linear-gradient(to bottom right, var(--bg-one), var(--bg-two));
  border: 12px solid rgba(176, 161, 92, 0.7);
  border-radius: 10px;
  box-shadow: 4px 4px 7px 2px #bbbbbb;
}

.card:hover {
  transition: .5s;
  box-shadow: 4px 4px 7px 5px #bababa;
}

.bg-text h1 {
  font-size: 80px;
  padding: .5rem 0;
}
.bg-text p {
  padding: .5rem 0;
}

.bg-text p .bold {
  font-weight: 600;
}


.dr__ltr {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: .5em;
}

.d__ltr {
  padding-bottom: .4em;
}

.r__ltr {
  margin-top: .4em;
}

.heading {
  font-family: 'Libre Baskerville', serif;
}
.contact__details {
  font-family: 'ABeeZee', sans-serif; 
  font-size: 40px;
}
.scroll {
  font-family: 'Pinyon Script', cursive;
  font-size: 60px;
}

button {
  padding: .5em;
  background-color: var(--gold-one);
  color: var(--bg-one);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
button:hover {
  background-color: var(--gold-two);
}

/* .bg-text {
  text-decoration: none;
  color: #B0A15C;
} */

@media screen and (max-width: 400px) {
  .contact {
    padding: 1em;
  }
  .card {
    width: 80%;
    padding: 5em;
    background-image: linear-gradient(to bottom right, var(--bg-one), var(--bg-two));
    border: 4px solid rgba(176, 161, 92, 0.7);
    border-radius: 10px;
    box-shadow: 4px 4px 7px 2px #bbbbbb;
  }
  .bg-text h1 {
    font-size: 30px;
  }
  .bg-text p {
    padding: .5rem 0;
    font-size: 20px;
  }
  
  .bg-text p .bold {
    font-weight: 600;
  }
  
  .dr__ltr {
    display: flex;
    flex-direction: row;
    padding: .5em;
    font-size: 30px;
  }
  
  .d__ltr {
    padding-bottom: .4em;
  }
  
  .r__ltr {
    margin-top: .4em;
  }
  
  button {
    padding: .5em;
    background-color: var(--gold-one);
    color: var(--bg-one);
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  button:hover {
    background-color: var(--gold-two);
  }
}